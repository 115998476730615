import {api} from '../../axios'
import { Dispatch } from 'redux'
import { CLIENT_CREATED } from './actionTypes'

export const createClient = (user_id: any, name: string, mobile_number: string, zip_code: string, selections: any) => {
    return (dispatch: (arg0: { type: string; payload: { newClient: any } }) => any) => {
        return api.post('/clients/clientinfo', {
            user_id,
            name, 
            mobile_number,
            zip_code,
            selections
        }).then(
            (resp) => { return dispatch({
                type: CLIENT_CREATED,
                payload: {
                    newClient: resp.data.newClient
                }
            })}
        )
    }
}

export const updateClient = (id: number, selections: any) => {
    return (dispatch: (arg0: { type: string; payload: { newClient: any } }) => any) => {
        return api.post('/clients/updateclientinfo', {
            id,
            selections
        }).then(
            (resp) => { return dispatch({
                type: CLIENT_CREATED,
                payload: {
                    newClient: resp.data.newClient
                }
            })}
        )
    }
}