
export interface Client {
    id: number,
    user_id: number,
    created_on: Date,
    name: string,
    mobile_number: number,
    zip_code: number,
    selections: []
}

export const EmptyClient: Client = {
    id: 0,
    user_id: 0,
    created_on: new Date,
    name: '',
    mobile_number: 0,
    zip_code: 0,
    selections: []
}