import React, { useEffect } from 'react';

import Welcome from './Welcome'
import ProviderSelection from './ProviderSelection'
import AdditionalProviderSelection from './AdditionalProviderSelection'
import CustomerForm from './CustomerForm'
import Confirmation from './Confirmation'
import { Route, Redirect, Switch } from 'react-router-dom'
import './App.css';

function App() {

  return (
    <div className="App">
      <Switch>
          <Route path='/providerselection' component={ProviderSelection}/>
					<Route path='/customerform' component={CustomerForm}/>
          <Route path='/additionalproviderselection' component={AdditionalProviderSelection}/>
          <Route path='/confirmation' component={Confirmation}/>
					<Route path='/*'><Welcome/></Route>
					<Route path='*'>
						<Redirect to='/'/>
					</Route>
				</Switch>
    </div>
  );
}

export default App;
