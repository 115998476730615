export const providerSelection = (providers: any[]) => (dispatch: (arg0: { type: string; payload: { providers: any[] } }) => void) => {
    dispatch({
     type: 'PROVIDER_SELECTION',
     payload: {
         providers
        }
    })
   }

export const clearProviderSelection = () => (dispatch: (arg0: { type: string }) => void) => {
    dispatch({
        type: 'CLEAR_PROVIDER_SELECTION'
    })
}