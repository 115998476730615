import {api} from '../../axios'
import { AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { USER_CREATED } from './actionTypes'

export const createUser = (name: string) => {
    return (dispatch: (arg0: { type: string; payload: { newUser: any } }) => any) => {
        return api.post('/users', {
            name
        }).then(
            (resp) => { return dispatch({
                type: USER_CREATED,
                payload: {
                    newUser: resp.data.newUser
                }
            })}
        )
    }
}


