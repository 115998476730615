import React,{ useState } from 'react'
import './ProviderSelection.scss'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Icon, Button } from 'semantic-ui-react'
import { providerSelection } from './store/actions/selectionAction';
const _ = require('lodash')

const ProviderSelection = (props) => {
    const [disable, setDisable] = useState(true)
    const dispatch = useDispatch()
    const history = useHistory()
    const [providerArray,] = useState([])
    const [stateIndex, setStateIndex] = useState('')
    const knxLogo = <img src={require('./images/KNX-logo.png')} style={{ width: '50px', height: '35px'}} alt='KNX Logo'/>

    const providers = [
        {name: 'Yard Care', image: require('./images/wheelbarrow-color.svg')},
        {name: 'Pool Maintenance', image: require('./images/hot-pool-color.svg')},
        {name: 'Solar', image: require('./images/solar-panel-color.svg')},
        {name: 'Landscaping', image: require('./images/park-color.svg')},
        {name: 'Pest Control', image: require('./images/mosquito-color.svg')},
        {name: 'Pool Design/Build', image: require('./images/swimming-pool-color.svg')},
        {name: 'Handyman', image: require('./images/fix-color.svg')},
        {name: 'Electrician', image: require('./images/plug-color.svg')},
        {name: 'Plumber', image: require('./images/plumbing-color.svg')},
        {name: 'Masonry', image: require('./images/masonry-color.svg')},
        {name: 'Painter', image: require('./images/brush-color.svg')},
        {name: 'Floor coverings', image: require('./images/floor-color.svg')},
        {name: 'Home Security', image: require('./images/smart-home-color.svg')},
        {name: 'Dental', image: require('./images/teeth-color.svg')},
        {name: 'Orthodontics', image: require('./images/dentist-color.svg')}
    ]
    
    const handleProviderSelection = async ( providerImage, providerName) => {
        setDisable(false)
        if (!providerArray.some(item => item.name === providerName)){
           await providerArray.push({name: providerName, image: providerImage})
        }else{
            await  _.remove(providerArray, function(o) { return o.name == providerName })
        }
        setStateIndex(-1)
        dispatch(providerSelection(providerArray))
    }

    return(
        <div id='provider-selection-page'>
            <div className='provider-select-interests-header'>Select your interests</div>
            <div className='select-interests-divider'/>
            <div className='select-interests-pane'>
                {
                    providers.map((p, id) => {
                        return (
                            <div key={id} className='interests-container' onClick={() => { handleProviderSelection( p.image, p.name); setStateIndex(id)}}>
                                <img src={p.image} style={{ width: '40px', height: '40px'}} alt='yard care'/>
                                {
                                    providerArray.find((item) => item.name === p.name)
                                    ? <Icon className='interests-checkmark' color='green' name='check' />
                                    : null && console.log('checked no no',providerArray)
                                }
                                <br/>
                                <div className='provider-name'>{p.name}</div>
                            </div>
                        )}
                    )
                }
            </div>
            <div id='footer-container'>
                <div className='footer-button-container' style={{ marginTop: '30px'}}>
                    <Button 
                        as={Link}
                        to='/customerform'
                        disabled={disable}
                        className='footer-confirmation-button-blue'
                        >
                        CONTINUE
                    </Button>
                </div>
                <div className='footer-logo-container'>
                    {knxLogo}
                </div>
            </div>
        </div>
    )
}

export default ProviderSelection