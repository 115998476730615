import { combineReducers } from 'redux';
import SelectionReducer, { SelectionState } from './SelectionReducer';
import UserReducer, { UserState } from './UserReducer'
import ClientReducer, { ClientState } from './ClientReducer';

export interface GlobalState {
    selection: SelectionState,
    user: UserState,
    client: ClientState
}

export default combineReducers({
    selection: SelectionReducer,
    user: UserReducer,
    client: ClientReducer
});