import {USER_CREATED} from '../actions/actionTypes'
import { User, EmptyUser } from '../../models/User'

export interface UserState {
    user: User
}

const INITIAL_STATE = {
    user: EmptyUser
}

export default (state = INITIAL_STATE, action: { type: any; payload: { newUser: any } }) => {
    switch (action.type) {
        case USER_CREATED:
            return {
                ...state,
                user: action.payload.newUser
            }
        default:
            return state
    }
}