import React from 'react';
import './Confirmation.scss'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { clearProviderSelection } from './store/actions/selectionAction';

const Confirmation = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const handshakeImage = <img src={require('./images/handshake.svg')} style={{ width: '90px', height: '80px'}} alt='handshake'/>
    const knxLogo = <img src={require('./images/KNX-logo.png')} style={{ width: '50px', height: '35px'}} alt='KNX Logo'/>
    const addServicesIcon = <img src={require('./images/add.svg')} style={{ width: '20px', height: '20px', marginTop: '10px'}} alt='Add Services'/>

    const confirmedProviderArray = useSelector((state) => state.selection.providers.providers)

    const clearSelection = () => {
            dispatch(clearProviderSelection())
    }

    const closeWindow = () => {
        window.open("about:blank", "_self");
        window.close();
    }

    return(
        <div style={{ minHeight: '100vh' }}>
        <div id='confirmation-page' style={{ paddingBottom: '4rem'}}>
            <h2>Voila!</h2>  
            {handshakeImage}
            <p>We will text your special offer shortly for the following services:</p>
                <div className='confirmed-interests-pane'>
                    {
                        confirmedProviderArray
                        ? confirmedProviderArray.map(item => {
                            return(
                                <div className='confirmed-interests-container'>
                                    <img src={item.image} style={{ width: '40px', height: '40px'}} alt='img'/>
                                    <br/>
                                    <div className='provider-name'>{item.name}</div>
                                </div>
                            )
                        })
                        : null
                    } 
                    <div className='confirmed-interests-container'>
                        <Link to='/additionalproviderselection' style={{ cursor: 'pointer'}}>
                                <div style={{ width: '40px', height: '40px', marginBottom: '5px', border: '1px solid gray', borderRadius: '5px' }}>{addServicesIcon}</div>
                                <div className='provider-name'>Add Services</div>
                        </Link>
                    </div>
                </div>
        </div> 
                <div id='footer-container' style={{ position: 'relative', bottom: '5em'}}>
                        <div className='footer-button-container' style={{ marginBottom: '30px'}}>
                        </div>
                        {/* <div className='footer-logo-container'>
                            {knxLogo}
                        </div> */}
                </div>
        </div>    
    )
}

export default Confirmation