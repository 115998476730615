import {CLIENT_CREATED} from '../actions/actionTypes'
import { Client, EmptyClient } from '../../models/Client'

export interface ClientState {
    client: Client
}

const INITIAL_STATE = {
    client: EmptyClient
}

export default (state = INITIAL_STATE, action: { type: any; payload: { newClient: any } }) => {
    switch (action.type) {
        case CLIENT_CREATED:
            return {
                ...state,
                client: action.payload.newClient
            }
        default: 
            return state
    }
}