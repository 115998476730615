import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import './CustomerForm.scss'
import './Footer.scss'
import { Button, Input } from 'semantic-ui-react'
import {createClient} from './store/actions/clientActions'
import {getUser} from './store/selectors/UserSelectors'

const CustomerForm = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [phoneValue, setPhoneValue] = useState('')
    const [nameValue, setNameValue] = useState('')
    const [zipCodeValue, setZipCodeValue] = useState('')
    const knxLogo = <img as={Link} to='/' src={require('./images/KNX-logo.png')} style={{ width: '50px', height: '35px'}} alt='KNX Logo'/>
    const fiveStarsImage = <img src={require('./images/fivestars.svg')} style={{ width: '200px', height: '35px', marginBottom: '25px'}} alt='five star rating'/>
    const [phoneError, setPhoneError] = useState('none')
    const [quoteIndex, setQuoteIndex] = useState(0)
    const user = useSelector((state) => getUser(state))
    const selection = useSelector((state) => state.selection.providers.providers)
    const letters = /^[A-Za-z]+$/;

    const phoneErr = () => {
        if (phoneValue.length < 12 || phoneValue.match(letters)){
            setPhoneError('')
        } else {
            setPhoneError('none')
        }
    }

    const handleClientInfo = async () => {
        const userId = user.id
        const selectionName = selection ? JSON.stringify(selection.map(s => s.name)) : null
        const formattedPhoneNumber = phoneValue.replace(/-/g, '')
        await dispatch(createClient(userId, nameValue, formattedPhoneNumber, zipCodeValue, selectionName))
    }

    const quotes = [
        {quote: "I love this service, it was a real-life saver in the middle of a stressful move. I would recommend this free service to anyone considering moving. Thank you KNX!", name: 'Trish - Washington, Utah'},
        {quote:"Wow, such an easy process. I selected the services that we needed, and the providers texted me directly. Super simple process, one I highly recommend if you dont like unnecessary stress.", name: 'Brooke - Mesquite, Nevada'},
        {quote: "Scanning the KNX QR code seriously took away a portion of my worries associated with moving, super simple process and huge benefit to me as a new home owner to a new area. Thank you!", name:'Scott - St. George, Utah'},
        {quote: "Moving to a new area is tough when you do not know anyone, where to find services, or who to trust. KNX took the guess work out of it. With their easy to use app, I was able to find several trusted providers.", name: 'Jared - Santa Clara, Utah'},
        {quote: "We found our dream home, but needed to find a reputable landscaper, bug man, and pool maintenance company. KNX made the process easy and we were able to find qualified providers in their respective fields, right at the closing table.", name: 'JoAnne - Washington, Utah'}
    ]

    useEffect(() => {
        setInterval(() => {    
           const index = Math.floor(Math.random() * quotes.length)
           setQuoteIndex(index)
        },6000)
    },[])
  
        
    return(      
        <div id='customer-form-page'>
            <div className='customer-form-container'>
                {fiveStarsImage}
                <br/>
                <div className='form-testimonial-text'>
                        {quotes[quoteIndex].quote}
                        <br/>
                        {quotes[quoteIndex].name}
                </div>
                <div className='customer-form-divider'/>
                <div className='customer-form-input-container'>
                    <h5>Please enter your:</h5>
                    <Input value={phoneValue.length > 1 && !phoneValue.match(letters) ? phoneValue.match(/\d{3}(?=\d{2,3})|\d+/g).join("-") : null}
                        onChange={(e) => {setPhoneValue(e.target.value); setPhoneError('none')}}
                        className={ phoneError ? 'customer-form-input' : 'customer-form-input-invalid-number'}
                        placeholder='Mobile number' 
                        maxLength='12'
                        onBlur={() => phoneErr()}>
                    </Input>
                    <br/>
                    <Input type='text' value={nameValue} disabled={phoneValue.length < 12 || phoneValue.match(letters) } onChange={(e) => {setNameValue(e.target.value)}} className='customer-form-input' placeholder='Name'/>
                    <br/>
                    <Input value={zipCodeValue} maxLength='5' disabled={phoneValue.length < 12 || phoneValue.match(letters)} onChange={(e) => {setZipCodeValue(e.target.value)}} className='customer-form-input' placeholder='Zip Code'/>
                </div>
                </div>
                <div id='footer-container'>
                    <div className='footer-button-container' style={{ marginTop: '100px'}}>
                        <Button 
                            as={Link}
                            to='/confirmation'
                            disabled={phoneValue.length < 12 || nameValue.length === 0 || zipCodeValue.length < 5 }
                            className='footer-confirmation-button-white'
                            onClick={() => handleClientInfo()}
                            >CONTINUE
                        </Button>
                    </div>
                    <div className='footer-logo-container'>
                        {knxLogo}
                    </div>
                </div>
        </div>
    )
}

export default CustomerForm