import React, { useEffect } from 'react'
import './Welcome.scss'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Button } from 'semantic-ui-react'
import { createUser } from './store/actions/userActions'
import {api} from './axios'

const Welcome = () => {
    const dispatch = useDispatch()
    const welcomePageLogo = <img src={require('./images/welcome-page-logo.png')} style={{ width: '300px', height: '300px'}} alt='welcome home art'/>
    const knxLogo = <img src={require('./images/KNX-logo.png')} style={{ width: '50px', height: '35px'}} alt='KNX Logo'/>

    const handleUser = () => {
        const customPathName = window.location.pathname.replace('/', '')
        dispatch(createUser(customPathName))
        console.log('whats created', window.location.pathname)
    }

    return(
        <div id='welcome-page'>
           <div className='welcome-text-container'>
               <h1>Welcome to KNX</h1>
               <p>A free service connecting you with the best providers in your area, rated by people like you.</p>
               {welcomePageLogo}
            </div>
                <div id='footer-container' style={{ position: 'absolute'}}>
                    <div className='footer-button-container'>
                            <Button 
                                as={Link}
                                to='/providerselection'
                                className='footer-confirmation-button-blue'
                                onClick={() => handleUser()}
                                >CONTINUE
                            </Button>
                        </div>
                        <div className='footer-logo-container'>
                            {knxLogo}
                        </div>
                </div>
        </div>
    )
}

export default Welcome