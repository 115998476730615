import { PROVIDER_SELECTION, CLEAR_PROVIDER_SELECTION } from '../actions/actionTypes'

export interface SelectionState {
  selection: Selection
}

const INITIAL_STATE = {
  providers: []
}

export default (state = INITIAL_STATE, action: { type: any; payload: any }) => {
    switch (action.type) {
     case PROVIDER_SELECTION:
      return {
        ...state,
       providers: action.payload
      }
      case CLEAR_PROVIDER_SELECTION:
        return {
          ...state,
          providers: INITIAL_STATE
        }
     default:
      return state
    }
   }